import {axiosInstance, api} from './api.config';

export const bookingIdCall = (PurchId, PurchPin, token) => {
  const params = {
    PurchId,
    PurchPin,
    token,
    type: 'json',
    version: 'V1',
  };
  return axiosInstance.get(api.bookingIdUrl, {params}).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

export const bookingDetailsCall = (purchId, bookingId, token) => {
  const params = {
    purchId,
    bookingId,
    token,
    type: 'json',
    version: 'V1',
  };
  return axiosInstance.get(api.bookingDetailsUrl, {params}).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

export const getBookingAreasCall = (purchId, token) => {
  const params = {
    purchId,
    token,
    type: 'json',
    version: 'V1',
  };
  return axiosInstance.get(api.areasUrl, {params}).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

export const getBookingUnitsCall = (purchId, token) => {
  const params = {
    purchId,
    token,
    type: 'json',
    version: 'V1',
  };
  return axiosInstance.get(api.unitsUrl, {params}).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

export const getBookingSlotsCall = (purchId, DeliveryId, token) => {
  const params = {
    purchId,
    DeliveryId,
    token,
    type: 'json',
    version: 'V1',
  };
  return axiosInstance.get(api.timeSlotsUrl, {params}).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

export const addNewPoCall = (
  purchId,
  purchIdFieldValue,
  purchPinFieldValue,
  BookingId,
  Token
) => {
  const data = {
    PurchId: purchId,
    AdditionalPurchId: purchIdFieldValue,
    PurchPin: purchPinFieldValue,
    BookingId,
    Token,
    Version: 'V1',
  };
  return axiosInstance.post(api.addPoUrl, data).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

export const removePoCall = (
  purchId,
  purchIdFieldValue,
  purchPinFieldValue,
  BookingId,
  Token
) => {
  const data = {
    PurchId: purchId,
    AdditionalPurchId: purchIdFieldValue,
    PurchPin: purchPinFieldValue,
    BookingId,
    Token,
    Version: 'V1',
  };
  return axiosInstance.post(api.removePoUrl, data).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

//@PARAMS
//purchIds: [ { "PurchId": purchId } ]
//areasWithUnits: [ { "Qty": 1, "StoreAreaId": FA, UnitId: VSSPallet }]
export const addNewDeliveryCall = (
  bookingId,
  purchIds,
  areasWithUnits,
  purchId,
  token
) => {
  const data = {
    BookingIdField: bookingId,
    JADGRDeliveryOrders: purchIds,
    JADGRDeliveryUnits: areasWithUnits,
    purchId,
    token,
  };
  return axiosInstance.put(api.addDeliveryUrl, data).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

export const updateBookingCall = ({values, purchId, bookingId, token}) => {
  const data = {
    BookingId: bookingId,
    TransportCompanyContactEmail: values.transportEmail,
    TransportCompanyContactName: values.transportContactName,
    TransportCompanyContactNumber: values.transportContactNum,
    TransportCompanyName: values.transportCompany,
    purchId,
    Token: token,
    Version: 'v1',
  };
  return axiosInstance.put(api.updateBookingUrl, data).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};

//@PARAMS
// deliveryOrder: [{ PurchId }]
// deliveryUnits: [
//   { Qty, StoreAreaId: WMSStoreArea, UnitId: Symbol(eg VSSPallet)  }
// ]
// timeSlotId: matches to WorkCalendarDateLineRefRecIdField - RecId from slots
export const updateDeliveryCall = ({
  purchId,
  bookingId,
  deliveryOrders,
  deliveryUnits,
  deliveryId,
  vehicleReg,
  timeSlotId,
  token,
}) => {
  const data = {
    BookingIdField: bookingId,
    JADGRDeliveryOrders: deliveryOrders,
    JADGRDeliveryUnits: deliveryUnits,
    DeliveryIdField: deliveryId,
    VehicleNumField: vehicleReg || '',
    WorkCalendarDateLineRefRecIdField: timeSlotId,
    purchId,
    Token: token,
    Version: 'V1',
  };
  return axiosInstance.put(api.updateDeliveryUrl, data).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};
