import {axiosInstance, api} from './api.config';

export const confirmPurchaseOrder = (
  purchId,
  purchPin,
  deliveryDate,
  values,
  token
) => {
  const data = {
    PurchId: purchId,
    PurchPin: purchPin,
    JADConfirmedBy: values.name,
    JADVendEmail: values.email,
    DeliveryDate: deliveryDate,
    Token: token,
    Version: 'v1',
  };
  return axiosInstance.put(api.confirmOrderUrl, data).then(res => {
    if (res.data.Data) return res.data.Data;
    else if (res.data.Error && res.data.Messages.length)
      throw res.data.Messages[0];
  });
};
