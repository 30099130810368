import React from 'react';

import ModalClass from './Modal';
import {
  ModalWrapper,
  TextWrapper,
  Title,
  ButtonContainer,
  SuccessButton,
  ErrorButton,
} from './AlertModal.style';

const AlertModal = props => {
  const {
    renderTitle,
    renderContent,
    showModal,
    onClose,
    customStyle,
    error,
    callback,
  } = props;

  return (
    <ModalClass isOpen={showModal} onClose={onClose} customStyle={customStyle}>
      <ModalWrapper>
        <Title error={error}>{renderTitle}</Title>
        <TextWrapper>{renderContent}</TextWrapper>
        <ButtonContainer>
          {error ? (
            <ErrorButton onClick={callback}>OK</ErrorButton>
          ) : (
            <SuccessButton onClick={callback}>OK</SuccessButton>
          )}
        </ButtonContainer>
      </ModalWrapper>
    </ModalClass>
  );
};

export default AlertModal;
